@import './colors';

$btn-default-color: $primary;
$btn-default-bg: transparent;
$btn-default-border: $primary;


.btn {
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    // We want to override the default btn padding, but still allow the sm/lg variants
    --bs-btn-padding-x: 12px;
    --bs-btn-padding-y: 6px;
    &.btn-sm {
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.5rem;
    };

    &.btn-lg {
        --bs-btn-padding-y: 0.5rem;
        --bs-btn-padding-x: 1rem;
    };


    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
        }
    }
}

.btn-container {
    display: inline-block;
    .btn {
        margin-right: 0.333em;
        &:last-child {
            margin-right: 0;
        }
    }
}

.btn .caret {
    margin-left: 4px;
}

.btn-rounded {
    border-radius: 25px;
}


.btn-empty-primary{
    color: $primary;
    background: none;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        color: $primary-10;
        text-decoration: underline;
    }
    i {
        text-decoration: none;
        padding-right: 6px;
    }
}

.btn-light-primary {
    color: $primary;
    background-color: $primary-9;
    border-color: $primary-9;

    &:focus,
    &.focus {
        color: $primary;
        background-color: darken($primary-9, 7%);
        border-color: darken($primary-9, 12%);
    }
    &:hover {
        color: $primary;
        background-color: darken($primary-9, 7%);
        border-color: darken($primary-9, 12%);
    }
}

.btn-empty-light {
    color: $text;
    background-color: $gray-16;
    &:focus,
    &.focus {
        color: $text-black;
        background-color: darken($gray-16, 7%);
        border-color: darken($gray-16, 12%);
    }
    &:hover {
        color: $text-black;
        background-color: darken($gray-16, 7%);
        border-color: darken($gray-16, 12%);
    }
}

.btn-outline-secondary {
    color: $text;
}
